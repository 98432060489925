import React, { useEffect, useState } from 'react';
import { format, addDays, startOfWeek, isSameDay, parseISO, isBefore, parse, addHours } from 'date-fns';
import { getDocs, collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';

const TutorCalendar = ({ tutor, selectedDate, setSelectedDate, currentDayOffset, setCurrentDayOffset }) => {
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const pricePerBooking = 15; // Price per booking

  const isBooked = (date, start) => {
    return tutor.bookings.some(
      (booking) => isSameDay(parseISO(booking.date), date) && booking.startTime === start
    );
  };

  const isCanceled = (date, start) => {
    return tutor.canceledBookings?.some(
      (booking) => isSameDay(parseISO(booking.date), date) && booking.startTime === start
    ) || false;
  };

  const isSelected = (date, start) => {
    return selectedDate.some(
      (selected) => isSameDay(selected.date, date) && selected.start === start
    );
  };

  const handleDateClick = (date, start) => {
    const selectedDateTime = { date, start, price: pricePerBooking };
    const isAlreadySelected = selectedDate.some(
      (selected) =>
        isSameDay(selected.date, selectedDateTime.date) && selected.start === selectedDateTime.start
    );

    if (isAlreadySelected) {
      setSelectedDate(selectedDate.filter((selected) => !isSameDay(selected.date, date) || selected.start !== start));
    } else {
      setSelectedDate([...selectedDate, selectedDateTime]);
    }
  };

  const today = new Date();
  const startDate = addDays(startOfWeek(today, { weekStartsOn: 0 }), currentDayOffset);

  const handlePrevWeek = () => {
    if (currentDayOffset > 0) {
      setCurrentDayOffset(currentDayOffset - 7);
    }
  };

  const handleNextWeek = () => {
    setCurrentDayOffset(currentDayOffset + 7);
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return `${format(date, 'h:mm a')} EST`;
  };

  return (
    <div className="mt-4 p-4 bg-white rounded-lg shadow">
      <h4 className="text-lg font-semibold mb-4 text-center">
        Calendar: Starting from {format(startDate, 'MMMM d, yyyy')}
      </h4>
      <div className="flex justify-between mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          onClick={handlePrevWeek}
          disabled={currentDayOffset <= 0}
        >
          Prev Week
        </button>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          onClick={handleNextWeek}
        >
          Next Week
        </button>
      </div>
      <div className="grid grid-cols-7 gap-4">
        {weekDays.map((day, index) => (
          <div key={index} className="text-center font-bold text-gray-600">
            {day}
          </div>
        ))}
        {Array(7)
          .fill(null)
          .map((_, index) => {
            const date = addDays(startDate, index);
            const dateString = format(date, 'yyyy-MM-dd');
            const availableTimeSlots = Array.isArray(tutor.availabilities[dateString])
              ? tutor.availabilities[dateString]
              : [];

            return (
              <div key={index} className="flex flex-col items-center">
                <div className="text-lg font-semibold mb-2">{format(date, 'd')}</div>
                <div className="flex flex-col flex-1 w-full">
                  {availableTimeSlots.map((startTime, idx) => {
                    const isBooked = tutor.bookings.some(
                      (booking) => isSameDay(parseISO(booking.date), date) && booking.startTime === startTime
                    );
                    const isCanceled = tutor.canceledBookings?.some(
                      (booking) => isSameDay(parseISO(booking.date), date) && booking.startTime === startTime
                    ) || false;
                    const isSelected = selectedDate.some(
                      (selected) => isSameDay(selected.date, date) && selected.start === startTime
                    );

                    return (
                      <div
                        key={idx}
                        className={`rounded-lg p-2 m-1 text-center cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 w-full ${
                          isBooked
                            ? 'bg-red-100 text-red-800'
                            : isCanceled
                            ? 'bg-yellow-100 text-yellow-800'
                            : isSelected
                            ? 'bg-blue-100 text-blue-800'
                            : isBefore(date, today)
                            ? 'bg-gray-100 text-gray-800 cursor-not-allowed'
                            : 'bg-green-100 text-green-800'
                        }`}
                        onClick={() =>
                          !isBooked &&
                          !isCanceled &&
                          !isBefore(date, today) &&
                          handleDateClick(date, startTime)
                        }
                      >
                        {formatTime(startTime)} - ${pricePerBooking}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const TutorCard = ({ tutor, selectedDate, setSelectedDate, currentDayOffset, setCurrentDayOffset }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105">
    <div className="p-6">
      <h2 className="text-2xl font-bold text-gray-800">{tutor.name}</h2>
      <p className="text-gray-600">{tutor.subject}</p>
      <p className="text-gray-600 mb-4">{tutor.bio}</p>
      <TutorCalendar
        tutor={tutor}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        currentDayOffset={currentDayOffset}
        setCurrentDayOffset={setCurrentDayOffset}
      />
    </div>
  </div>
);

const TutorList = () => {
  const [tutors, setTutors] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [currentDayOffset, setCurrentDayOffset] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTutors = async () => {
      const tutorsSnapshot = await getDocs(collection(firestore, 'tutors'));
      const tutorsData = await Promise.all(
        tutorsSnapshot.docs.map(async (doc) => {
          const tutorData = { id: doc.id, ...doc.data() };
          tutorData.availabilities = transformAvailability(tutorData.availability);
          tutorData.bookings = await fetchBookings(tutorData.id);
          return tutorData;
        })
      );
      setTutors(tutorsData);
    };

    const unsubscribe = onSnapshot(collection(firestore, 'tutors'), fetchTutors);

    return () => unsubscribe();
  }, [currentDayOffset]);

  const transformAvailability = (availability) => {
    const transformedAvailability = {};
    for (const date in availability) {
      const { startTime, endTime } = availability[date];
      const timeSlots = generateTimeSlots(startTime, endTime);
      transformedAvailability[date] = timeSlots;
    }
    return transformedAvailability;
  };

  const generateTimeSlots = (startTime, endTime) => {
    const timeSlots = [];
    let currentTime = parse(startTime, 'HH:mm', new Date());
    const endDateTime = parse(endTime, 'HH:mm', new Date());

    while (currentTime <= endDateTime) {
      const formattedTime = format(currentTime, 'HH:mm');
      timeSlots.push(formattedTime);
      currentTime = addHours(currentTime, 1);
    }

    return timeSlots;
  };

  const fetchBookings = async (tutorId) => {
    const bookingsSnapshot = await getDocs(collection(firestore, 'bookings'));
    const bookings = bookingsSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((booking) => booking.tutorId === tutorId);
    return bookings;
  };

  const handleBooking = () => {
    const selectedDateTimes = selectedDate.map((selected) => ({
      date: format(selected.date, 'yyyy-MM-dd'),
      start: selected.start,
      price: selected.price,
    }));
    if (tutors.length > 0) {
      navigate(`/booking?tutorId=${tutors[0].id}&selectedDateTimes=${encodeURIComponent(JSON.stringify(selectedDateTimes))}`);
    } else {
      console.error('No tutors available for booking.');
    }
  };

  return (
    <div className="container mx-auto px-2 py-8">
      <h1 className="text-4xl font-bold text-center mb-8">Availabilities</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {tutors.map((tutor) => (
          <TutorCard
            key={tutor.id}
            tutor={tutor}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            currentDayOffset={currentDayOffset}
            setCurrentDayOffset={setCurrentDayOffset}
          />
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded shadow-lg"
          onClick={handleBooking}
          disabled={selectedDate.length === 0}
        >
          Book Selected Time Slots
        </button>
      </div>
    </div>
  );
};

export default TutorList;
