import React, { useEffect, useState } from 'react';
import { InfoSection, Pricing } from '../../components';
import { homeObjThree, homeObjTwo, homeObjFour, tutorInfoObj, tutorDetailObj, fetchTutorData } from './Data';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [tutor, setTutor] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTutorData(setTutor);
  }, []);

  const handleBookNowClick = () => {
    navigate('/tutor-list');
  };

  return (
    <>
      {tutor && (
        <InfoSection
          {...tutorInfoObj}
          headline={`Unlock Your Potential with ${tutor.name}`}
          description={`Welcome to Tutoring with ${tutor.name}! I'm here to guide you on your educational journey and help you achieve your academic goals. With my expertise and personalized approach, you'll gain the confidence and skills to excel in your studies.`}
          buttonLabel={`Book ${tutor.name} Now`}
          img={tutor.profilePicture}
          onClick={handleBookNowClick}
          dynamicContent={tutorDetailObj.dynamicContent(tutor)}
        />
      )}
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjTwo} />
      <Pricing />
     
    </>
  );
};

export default Home;
