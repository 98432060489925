import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../firebase';
import { format, parse, addHours } from 'date-fns';

const TutorDetails = () => {
  const { tutorId } = useParams();
  const [tutor, setTutor] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTutor = async () => {
      const tutorDoc = await getDoc(doc(firestore, 'tutors', tutorId));
      if (tutorDoc.exists()) {
        const tutorData = { id: tutorDoc.id, ...tutorDoc.data() };
        const profilePictureRef = ref(storage, `tutors/${tutorId}/profile-picture`);
        try {
          const profilePictureURL = await getDownloadURL(profilePictureRef);
          tutorData.profilePicture = profilePictureURL;
        } catch (error) {
          console.error('Error fetching profile picture:', error);
        }
        setTutor(tutorData);
        generateAvailabilities(tutorData);
      } else {
        console.error('Tutor not found');
      }
    };
    fetchTutor();
  }, [tutorId]);

  const generateAvailabilities = (tutor) => {
    const availabilities = [];
    for (const [day, times] of Object.entries(tutor.availability || {})) {
      if (times.start && times.end) {
        const startTime = parse(times.start, 'HH:mm', new Date());
        const endTime = parse(times.end, 'HH:mm', new Date());
        let currentTime = startTime;
        while (currentTime < endTime) {
          const formattedStart = format(currentTime, 'HH:mm');
          const formattedEnd = format(addHours(currentTime, 1), 'HH:mm');
          const isBooked = (times.bookings || []).some(
            (booking) => booking.startTime === formattedStart
          );
          availabilities.push({
            day,
            start: formattedStart,
            end: formattedEnd,
            isBooked,
          });
          currentTime = addHours(currentTime, 1);
        }
      }
    }
    setAvailabilities(availabilities);
  };

  if (!tutor) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">{tutor.name}</h1>
      {tutor.profilePicture && (
        <div className="w-64 h-64 mx-auto mb-8">
          <img
            src={tutor.profilePicture}
            alt={tutor.name}
            className="w-full h-full object-cover rounded-full"
          />
        </div>
      )}
      <p className="text-gray-700 mb-4">{tutor.bio}</p>
      <p className="text-gray-700 mb-4">
        <span className="font-bold">Subject:</span> {tutor.subject}
      </p>
      <h2 className="text-2xl font-bold mb-4">Availability:</h2>
      <ul className="space-y-2">
        {availabilities.map((availability, index) => (
          <li
            key={index}
            className={`px-4 py-2 rounded-md ${
              availability.isBooked ? 'bg-gray-200 text-gray-500' : 'bg-gray-100 text-gray-800'
            }`}
          >
            {availability.day} - {availability.start} to {availability.end}
            {!availability.isBooked && (
              <button
                className="ml-4 px-2 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={() =>
                  navigate(
                    `/booking?tutorId=${tutor.id}&day=${availability.day}&start=${availability.start}`
                  )
                }
              >
                Book
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TutorDetails;