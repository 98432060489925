import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useCart } from '../contexts/CartContext';

const BookingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tutorId = searchParams.get('tutorId');
    const selectedDateTimes = JSON.parse(searchParams.get('selectedDateTimes'));
    const { addToCart } = useCart();
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        note: '',
        paymentMethod: 'venmo',
        venmoUsername: '',
        paypalUsername: '',
    });
    const [error, setError] = useState('');
    const [tutorPaymentInfo, setTutorPaymentInfo] = useState({
        venmoLink: '',
        paypalLink: '',
    });

    const totalPrice = selectedDateTimes.length * 15;

    useEffect(() => {
        // Fetch tutor's payment info from Firebase
        const fetchTutorPaymentInfo = async () => {
            try {
                const tutorRef = doc(firestore, 'tutors', tutorId);
                const tutorDoc = await getDoc(tutorRef);
                const tutorData = tutorDoc.data();

                setTutorPaymentInfo({
                    venmoLink: tutorData.venmoLink || '',
                    paypalLink: tutorData.paypalLink || '',
                });
            } catch (error) {
                console.error('Error fetching tutor payment info:', error);
            }
        };

        fetchTutorPaymentInfo();
    }, [tutorId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleTermsChange = (e) => {
      setAgreedToTerms(e.target.checked);
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');

      if (!agreedToTerms) {
          setError('Please agree to the Terms and Conditions before proceeding with the booking.');
          return;
      }

        try {
            const tutorRef = doc(firestore, 'tutors', tutorId);
            const tutorDoc = await getDoc(tutorRef);
            const tutorData = tutorDoc.data();

            const bookingPromises = selectedDateTimes.map(async (selectedDateTime) => {
                const { date, start } = selectedDateTime;

                if (!tutorData.availability || !tutorData.availability[date]) {
                    throw new Error(`The selected date ${date} is not available for booking.`);
                }

                const isAlreadyBooked = (tutorData.availability[date].bookings || []).some(
                    (booking) => booking.startTime === start
                );

                if (isAlreadyBooked) {
                    throw new Error(`The time slot ${date} ${start} is already booked. Please choose another time.`);
                }

                const bookingData = {
                    tutorId,
                    studentName: formData.name,
                    studentEmail: formData.email,
                    studentPhone: formData.phone,
                    date,
                    startTime: start,
                    endTime: calculateEndTime(start),
                    note: formData.note,
                    paymentMethod: formData.paymentMethod,
                    venmoUsername: formData.venmoUsername,
                    paypalUsername: formData.paypalUsername,
                };

                const bookingDocRef = await addDoc(collection(firestore, 'bookings'), bookingData);
                const bookingId = bookingDocRef.id;

                const bookingDetails = {
                    id: bookingId,
                    studentName: formData.name,
                    studentEmail: formData.email,
                    startTime: start,
                    endTime: calculateEndTime(start),
                };

                await updateDoc(tutorRef, {
                    [`availability.${date}.bookings`]: arrayUnion(bookingDetails),
                });

                addToCart({
                    id: bookingId,
                    name: `Tutoring Session on ${date} at ${convertTo12HourFormat(start)}`,
                    price: tutorData.hourlyRate || 0,
                    quantity: 1,
                });

                return bookingId;
            });

            const bookingIds = await Promise.all(bookingPromises);
            const bookingIdsParam = encodeURIComponent(JSON.stringify(bookingIds));
            navigate(`/booking-confirmation?bookingIds=${bookingIdsParam}`);
        } catch (error) {
            console.error('Error saving booking:', error);
            setError(error.message);
        }
    };

    const calculateEndTime = (startTime) => {
        const [hours, minutes] = startTime.split(':');
        const endHours = parseInt(hours, 10) + 1;
        return `${endHours.toString().padStart(2, '0')}:${minutes}`;
    };

    const convertTo12HourFormat = (time) => {
        let [hours, minutes] = time.split(':');
        const suffix = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${suffix}`;
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-8 text-center">Booking Page</h1>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-8" role="alert">
                    {error}
                </div>
            )}

            <div className="mb-8">
                <h2 className="text-2xl font-bold mb-4">Selected Dates and Times (Eastern Standard Time):</h2>
                <ul className="space-y-4">
                    {selectedDateTimes.map((selectedDateTime, index) => (
                        <li key={index} className="flex items-center">
                            <div className="bg-blue-500 text-white font-bold py-2 px-4 rounded-l">
                                {selectedDateTime.date}
                            </div>
                            <div className="bg-blue-100 text-blue-800 font-bold py-2 px-4 rounded-r">
                                {convertTo12HourFormat(selectedDateTime.start)}
                            </div>
                        </li>
                    ))}
                </ul>
                <p className="text-xl font-bold mt-6">Total Price: ${totalPrice}</p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                        Name:
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                        Email:
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
                        Phone:
                    </label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div>
                    <label htmlFor="note" className="block text-gray-700 font-bold mb-2">
                        Note:
                    </label>
                    <textarea
                        id="note"
                        name="note"
                        value={formData.note}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ></textarea>
                </div>
                <div>
                    <label htmlFor="paymentMethod" className="block text-gray-700 font-bold mb-2">
                        Payment Method:
                    </label>
                    <div className="flex items-center space-x-4">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="venmo"
                                checked={formData.paymentMethod === 'venmo'}
                                onChange={handleInputChange}
                                className="mr-2"
                            />
                            <span>Venmo</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="paypal"
                                checked={formData.paymentMethod === 'paypal'}
                                onChange={handleInputChange}
                                className="mr-2"
                            />
                            <span>PayPal</span>
                        </label>
                    </div>
                </div>
                {formData.paymentMethod === 'venmo' && (
                    <div>
                        <label htmlFor="venmoUsername" className="block text-gray-700 font-bold mb-2">
                            Venmo Username:
                        </label>
                        <input
                            type="text"
                            id="venmoUsername"
                            name="venmoUsername"
                            value={formData.venmoUsername}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {tutorPaymentInfo.venmoLink && (
                            <p className="mt-2">
                                Tutor's Venmo: <a href={tutorPaymentInfo.venmoLink} target="_blank" rel="noreferrer" className="text-blue-500 underline">{tutorPaymentInfo.venmoLink}</a>
                            </p>
                        )}
                    </div>
                )}
                {formData.paymentMethod === 'paypal' && (
                    <div>
                        <label htmlFor="paypalUsername" className="block text-gray-700 font-bold mb-2">
                            PayPal Username:
                        </label>
                        <input
                            type="text"
                            id="paypalUsername"
                            name="paypalUsername"
                            value={formData.paypalUsername}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {tutorPaymentInfo.paypalLink && (
                            <p className="mt-2">
                                Tutor's PayPal: <a href={tutorPaymentInfo.paypalLink} target="_blank" rel="noreferrer" className="text-blue-500 underline">{tutorPaymentInfo.paypalLink}</a>
                            </p>
                        )}
                    </div>
                )}
                 <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="termsCheckbox"
                        checked={agreedToTerms}
                        onChange={handleTermsChange}
                        className="mr-2"
                    />
                    <label htmlFor="termsCheckbox" className="text-gray-700">
                        I agree to the{' '}
                        <button
                            type="button"
                            className="text-blue-500 underline focus:outline-none"
                            onClick={() => setShowModal(true)}
                        >
                            Terms and Conditions
                        </button>
                    </label>
                </div>

                <div>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Submit Booking
                    </button>
                </div>
            </form>

            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
                                <div className="space-y-4">
                                    <h3 className="text-lg font-bold">1. Booking and Payment</h3>
                                    <ul className="list-disc list-inside">
                                        <li>All tutoring sessions must be booked and paid for in advance to secure your spot.</li>
                                        <li>Payment is required in full at the time of booking. Your session will not be confirmed until the payment has been processed successfully.</li>
                                        <li>Accepted payment methods include Venmo and PayPal. Please ensure that you provide accurate payment information during the booking process.</li>
                                        <li>If you have any questions or issues regarding the payment process, please contact me immediately for assistance.</li>
                                    </ul>

                                    <h3 className="text-lg font-bold">2. Cancellation and Refund Policy</h3>
                                    <ul className="list-disc list-inside">
                                        <li>If you need to cancel or reschedule a session, please provide at least 24 hours' notice prior to the scheduled session time.</li>
                                        <li>Cancellations made more than 24 hours in advance will be eligible for a full refund or the option to reschedule the session, subject to availability.</li>
                                        <li>Cancellations made within 24 hours of the scheduled session time will not be eligible for a refund. However, you may still have the option to reschedule the session, subject to availability.</li>
                                        <li>In the event that I need to cancel a session due to unforeseen circumstances, you will be notified promptly, and a full refund will be issued or the session will be rescheduled at your convenience.</li>
                                    </ul>

                                    <h3 className="text-lg font-bold">3. Session Preparation and Attendance</h3>
                                    <ul className="list-disc list-inside">
                                        <li>Please arrive on time for your scheduled sessions. Late arrivals may result in a shortened session duration, but the full session fee will still apply.</li>
                                        <li>Come prepared with any necessary materials, such as textbooks, assignments, or specific questions you wish to discuss during the session.</li>
                                        <li>If you are unable to attend a scheduled session, please inform me as soon as possible. Failure to attend a session without prior notice will be treated as a late cancellation and will not be eligible for a refund.</li>
                                    </ul>

                                    <h3 className="text-lg font-bold">4. Student Conduct</h3>
                                    <ul className="list-disc list-inside">
                                        <li>During tutoring sessions, students are expected to maintain a respectful and professional demeanor.</li>
                                        <li>Harassment, discrimination, or any form of inappropriate behavior will not be tolerated. Engaging in such conduct may result in the immediate termination of the session and future sessions, without refund.</li>
                                        <li>Students are expected to actively participate in the learning process and put forth their best effort to achieve their academic goals.</li>
                                    </ul>

                                    

                                    <h3 className="text-lg font-bold">5. Confidentiality</h3>
                                    <ul className="list-disc list-inside">
                                        <li>I am committed to maintaining the confidentiality of all student information shared during tutoring sessions.</li>
                                        <li>Personal information, including contact details and academic records, will not be disclosed to any third party without the student's explicit consent, except as required by law.</li>
                                    </ul>

                                    <p>
                                        By proceeding with the booking and payment process, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you have any questions or concerns regarding these terms, please contact me prior to booking your session.
                                    </p>
                                    <p>
                                        I reserve the right to update or modify these terms and conditions at any time without prior notice. It is your responsibility to review the terms periodically for any changes.
                                    </p>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingPage;