export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTopLine: true,
    lightTextDesc: false,
    topLine: 'Contact Us',
    headline: 'Get in Touch with Us',
    description: 'Have a question or need assistance? Feel free to reach out to our friendly team. We\'re here to help!',
    buttonLabel: 'Contact Us',
    imgStart: '',
    img: require('../../images/contacts.png'),
    alt: 'Contact'
};

export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTopLine: true,
    lightTextDesc: true,
    topLine: 'Customer Support',
    headline: '24/7 Customer Support',
    description: 'Our dedicated customer support team is available 24/7 to assist you with any inquiries or issues you may have. We strive to provide prompt and helpful assistance.',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/contacts.png'),
    alt: 'Customer Support'
};

export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTopLine: true,
    lightTextDesc: false,
    topLine: 'Our Location',
    headline: 'Visit Our Office',
    description: 'Come visit our office located at 123 Main Street, City, Country. We\'d be delighted to meet you in person and discuss how we can assist you.',
    buttonLabel: 'Get Directions',
    imgStart: 'start',
    img: require('../../images/contacts.png'),
    alt: 'Location'
};

export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTopLine: true,
    lightTextDesc: true,
    topLine: 'Send Us a Message',
    headline: 'Drop Us a Line',
    description: 'You can also reach us by filling out the contact form on our website. We\'ll get back to you as soon as possible.',
    buttonLabel: 'Send Message',
    imgStart: 'start',
    img: require('../../images/contacts.png'),
    alt: 'Message'
};