import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PricingSection = styled.div `
  color: #fff;
  padding: 160px 0;
  background: #4b59f7;

  @media (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const PricingWrapper = styled.div `
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 50px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const PricingHeading = styled.h1 `
  margin-bottom: 50px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
`;

export const PricingContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 -30px 50px;
  flex-wrap: wrap;

  @media (min-width: 480px) and (max-width: 1200px) {
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    margin: 0 -15px 30px;
  }
`;

export const PricingCard = styled.div `
  background: #242424;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.3);
  width: 280px;
  text-decoration: none;
  border-radius: 10px;
  margin: 0 30px 30px;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.06);
  }

  @media (min-width: 480px) and (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 10px 20px;
    padding: 30px 20px;
  }
`;

export const PricingCardInfo = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-bottom: 30px;
`;

export const PricingCardIcon = styled.div `
  margin: 30px 0;
  color: #fff;

  @media (max-width: 480px) {
    margin: 20px 0;
  }
`;

export const PricingCardPlan = styled.h3 `
  margin-bottom: 15px;
  font-size: 30px;
  color: #fff;

  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

export const PricingCardFeatures = styled.ul `
  margin: 25px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`;

export const PricingCardFeature = styled.li `
  margin-bottom: 4px;
  font-size: 18px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

export const BookNowButton = styled(Link)
`
  border-radius: 50px;
  background: #fff;
  white-space: nowrap;
  padding: 14px 48px;
  color: #4b59f7;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #f2f2f2;
  }

  @media (max-width: 480px) {
    padding: 12px 36px;
    font-size: 18px;
  }
`;

export const PaymentMethodsContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 480px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;

export const PaymentMethodIcon = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: 0 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 480px) {
    width: 80px;
    height: 80px;
    margin: 10px 0;
  }
`;

export const PaymentMethodLogo = styled.img `
  width: 60%;
  height: 60%;
  object-fit: contain;
`;