import { getDocs, collection } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../../firebase';
import { Link } from 'react-router-dom';
import svg2 from '../../images/svg-2.svg';
import profile from '../../images/profile.jpg';

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Personalized Learning',
  headline: 'Tailored Tutoring Sessions Just for You',
  description: "At Tutoring with Leila, we provide personalized tutoring sessions to help you achieve your academic goals efficiently.",
  imgStart: '',
  img: svg2,
  alt: 'Learning',
  start: ''
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Student Success Stories',
  headline: 'Our Students Achieve Their Academic Goals',
  description: "With our expert guidance, students have improved their grades and gained confidence in their studies.",
  buttonLabel: 'Get Started Today',
  imgStart: 'start',
  img: profile,
  alt: 'Success',
  start: 'true'
};



export const tutorInfoObj = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Tutoring Services',
  headline: 'Unlock Your Potential with Leila',
  description: 'Leila is here to guide you on your educational journey and help you achieve your academic goals.',
  buttonLabel: 'Book Now',
  imgStart: '',
  alt: 'Tutor',
  start: '',
  linkTo: '/tutor-list'
};

export const tutorDetailObj = {
  headline: 'Unlock Your Potential with [Tutor Name]',
  description: 'Welcome to Tutoring with [Tutor Name]! I\'m here to guide you on your educational journey and help you achieve your academic goals. With my expertise and personalized approach, you\'ll gain the confidence and skills to excel in your studies.',
  dynamicContent: (tutor) => (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div>
          <p className="text-lg text-white mb-6">{tutor.tagline}</p>
          <p className="text-white mb-6">{tutor.bio}</p>
          {tutor.qualifications && Array.isArray(tutor.qualifications) && (
            <div>
              <h3 className="text-lg font-bold mb-2 text-blue-600">Qualifications:</h3>
              <ul className="list-disc list-inside mb-6 text-white">
                {tutor.qualifications.map((qualification, index) => (
                  <li key={index}>{qualification}</li>
                ))}
              </ul>
            </div>
          )}
          <p className="text-white mb-6">Experience: {tutor.experience}</p>
          {tutor.specialties && Array.isArray(tutor.specialties) && (
            <div>
              <h3 className="text-lg font-bold mb-2 text-blue-600">Specialties:</h3>
              <ul className="list-disc list-inside mb-6 text-white">
                {tutor.specialties.map((specialty, index) => (
                  <li key={index}>{specialty}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div>
          {tutor.subjects && Array.isArray(tutor.subjects) && (
            <div>
              <h3 className="text-2xl font-bold mb-4 text-white">Tutoring Subjects</h3>
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {tutor.subjects.map((subject, index) => (
                  <li key={index} className="bg-white rounded-lg shadow-lg p-6 transform transition-transform duration-500 hover:scale-105">
                    <h4 className="text-lg font-bold mb-2 text-blue-600">{subject.name}</h4>
                    <p className="text-gray-700">{subject.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <section className="text-center mb-16">
        <Link
          to="/contact"
          className="inline-block px-8 py-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none text-lg font-semibold shadow-md"
        >
          Get Started Today
        </Link>
      </section>
      {tutor.availability && Array.isArray(tutor.availability) && (
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-blue-600">Tutoring Availability</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {tutor.availability.map((slot, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-8 transform transition-transform duration-500 hover:scale-105">
                <h3 className="text-lg font-bold mb-4 text-blue-600">{slot.day}</h3>
                <p className="text-gray-700">
                  {slot.startTime} - {slot.endTime}
                </p>
              </div>
            ))}
          </div>
        </section>
      )}
      {tutor.successStories && Array.isArray(tutor.successStories) && (
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-blue-600">Student Success Stories</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {tutor.successStories.map((story, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-8 transform transition-transform duration-500 hover:scale-105">
                <p className="text-gray-700 italic">{story}</p>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  )
};

export const fetchTutorData = async (setTutor) => {
  const tutorsSnapshot = await getDocs(collection(firestore, 'tutors'));
  if (!tutorsSnapshot.empty) {
    const tutorDoc = tutorsSnapshot.docs[0];
    const tutorData = { id: tutorDoc.id, ...tutorDoc.data() };
    const profilePictureRef = ref(storage, `tutors/${tutorDoc.id}/profile-picture`);
    try {
      const profilePictureURL = await getDownloadURL(profilePictureRef);
      tutorData.profilePicture = profilePictureURL;
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
    setTutor(tutorData);
  }
};
