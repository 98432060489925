import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, query, collection, where, getDocs, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { firestore, storage } from '../firebase';
import { format, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const AvailabilityCalendar = ({ availability, setAvailability }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [formData, setFormData] = useState({
    name: '',
    bio: '',
    subject: '',
    successStories: [],
    qualifications: [],
    experience: '',
    specialties: [],
    venmoLink: '',
    paypalLink: '',
  });
  const handleMonthChange = (date) => {
    setCurrentMonth(date);
  };

  const handleDateSelect = (date) => {
    const dateString = format(date, 'yyyy-MM-dd');
    if (availability[dateString]) {
      const updatedAvailability = { ...availability };
      delete updatedAvailability[dateString];
      setAvailability(updatedAvailability);
    } else {
      setAvailability({
        ...availability,
        [dateString]: {
          startTime,
          endTime,
        },
      });
    }
  };

  const renderCalendarCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

    return days.map((day) => {
      const dateString = format(day, 'yyyy-MM-dd');
      const isAvailable = availability[dateString];

      return (
        <div
          key={day.toString()}
          className={`cursor-pointer text-center py-2 ${
            isAvailable ? 'bg-green-500 text-white' : 'bg-gray-100'
          }`}
          onClick={() => handleDateSelect(day)}
        >
          {format(day, 'd')}
        </div>
      );
    });
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={() => handleMonthChange(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1))}
        >
          Previous Month
        </button>
        <span className="text-lg font-bold">{format(currentMonth, 'MMMM yyyy')}</span>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={() => handleMonthChange(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
        >
          Next Month
        </button>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
          <div key={day} className="text-center font-bold">
            {day}
          </div>
        ))}
        {renderCalendarCells()}
      </div>
      <div className="mt-4">
        <label className="block text-gray-700 font-bold mb-2">Default Start Time:</label>
        <input
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mt-4">
        <label className="block text-gray-700 font-bold mb-2">Default End Time:</label>
        <input
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
    </div>
  );
};

const TutorDashboard = () => {
  const [tutor, setTutor] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    bio: '',
    subject: '',
    successStories: [],
    qualifications: [],
    experience: '',
    specialties: [],
  });
  const [availability, setAvailability] = useState({});
  const [blockoutDates, setBlockoutDates] = useState([]);
  const [bookedSessions, setBookedSessions] = useState([]);
  const [profilePicture, setProfilePicture] = useState(null); // Initialize profilePicture state
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (!user) {
      navigate('/tutor-signin');
      return;
    }

    const fetchTutor = async () => {
      const tutorRef = doc(firestore, 'tutors', user.uid);
      const tutorDoc = await getDoc(tutorRef);
      if (tutorDoc.exists()) {
        const tutorData = tutorDoc.data();
        setTutor(tutorData);
        setFormData({
          name: tutorData.name || '',
          bio: tutorData.bio || '',
          subject: tutorData.subject || '',
          successStories: tutorData.successStories || [],
          qualifications: tutorData.qualifications || [],
          experience: tutorData.experience || '',
          specialties: tutorData.specialties || [],
          venmoLink: tutorData.venmoLink || '',
          paypalLink: tutorData.paypalLink || '',
        });
        setAvailability(tutorData.availability || {});
        setBlockoutDates(tutorData.blockoutDates || []);
      } else {
        const newTutorData = {
          tutorId: user.uid,
          email: user.email,
          name: '',
          bio: '',
          subject: '',
          availability: {},
          blockoutDates: [],
        };
        await setDoc(tutorRef, newTutorData);
        setTutor(newTutorData);
        setFormData({
          name: '',
          bio: '',
          subject: '',
          successStories: [],
          qualifications: [],
          experience: '',
          specialties: [],
          venmoLink: '',
          paypalLink: '',
        });
        setAvailability({});
        setBlockoutDates([]);
      }
    };

    fetchTutor();
  }, [user, navigate]);

  useEffect(() => {
    if (!tutor) return;

    const fetchBookedSessions = async () => {
      const q = query(collection(firestore, 'bookings'), where('tutorId', '==', tutor.tutorId));
      const querySnapshot = await getDocs(q);
      const sessions = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setBookedSessions(sessions);
    };

    fetchBookedSessions();

    const fetchProfilePicture = async () => {
      try {
        const profilePictureRef = ref(storage, `tutors/${tutor.tutorId}/profile-picture`);
        const downloadURL = await getDownloadURL(profilePictureRef);
        setProfilePicture(downloadURL);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchProfilePicture();
  }, [tutor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const profilePictureRef = ref(storage, `tutors/${tutor.tutorId}/profile-picture`);
      await uploadBytes(profilePictureRef, file);
      const downloadURL = await getDownloadURL(profilePictureRef);
      setProfilePicture(downloadURL);
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  };

  const handleCancelBooking = async (bookingId) => {
    try {
      const bookingDoc = await getDoc(doc(firestore, 'bookings', bookingId));

      if (bookingDoc.exists()) {
        const bookingData = bookingDoc.data();
        const tutorDoc = await getDoc(doc(firestore, 'tutors', bookingData.tutorId));

        if (tutorDoc.exists()) {
          const tutorData = tutorDoc.data();
          const updatedAvailability = { ...tutorData.availability };
          const dateString = format(new Date(bookingData.date), 'yyyy-MM-dd');

          if (updatedAvailability[dateString]) {
            delete updatedAvailability[dateString];
          }

          await setDoc(doc(firestore, 'tutors', bookingData.tutorId), {
            ...tutorData,
            availability: updatedAvailability,
          });

          await deleteDoc(doc(firestore, 'bookings', bookingId));
          setBookedSessions((prevSessions) => prevSessions.filter((session) => session.id !== bookingId));
        } else {
          console.error('Tutor document not found');
        }
      } else {
        console.error('Booking document not found');
      }
    } catch (error) {
      console.error('Error canceling booking:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(firestore, 'tutors', tutor.tutorId), {
        ...formData,
        tutorId: tutor.tutorId,
        availability,
        blockoutDates,
      });
      console.log('Tutor data saved successfully');
    } catch (error) {
      console.error('Error saving tutor data:', error);
    }
  };
  const handleBlockoutDateChange = (dates) => {
    setBlockoutDates(dates);
  };
  if (!tutor) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Tutor Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-bold mb-4">Profile</h2>
          <div className="mb-8">
            {profilePicture ? (
              <img
                src={profilePicture}
                alt="Profile"
                className="w-40 h-40 rounded-full object-cover"
              />
            ) : (
              <div className="w-40 h-40 bg-gray-200 rounded-full"></div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureChange}
              className="mt-4"
            />
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="bio" className="block text-gray-700 font-bold mb-2">
                Bio:
              </label>
              <textarea
                id="bio"
                name="bio"
                value={formData.bio}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <div>
              <label htmlFor="subject" className="block text-gray-700 font-bold mb-2">
                Subject:
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="experience" className="block text-gray-700 font-bold mb-2">
                Experience:
              </label>
              <input
                type="text"
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleInputChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="specialties" className="block text-gray-700 font-bold mb-2">
                Specialties:
              </label>
              <input
                type="text"
                id="specialties"
                name="specialties"
                value={formData.specialties.join(', ')}
                onChange={(e) => setFormData({ ...formData, specialties: e.target.value.split(', ') })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="qualifications" className="block text-gray-700 font-bold mb-2">
                Qualifications:
              </label>
              <input
                type="text"
                id="qualifications"
                name="qualifications"
                value={formData.qualifications.join(', ')}
                onChange={(e) => setFormData({ ...formData, qualifications: e.target.value.split(', ') })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="successStories" className="block text-gray-700 font-bold mb-2">
                Success Stories:
              </label>
              <textarea
                id="successStories"
                name="successStories"
                value={formData.successStories.join('\n')}
                onChange={(e) => setFormData({ ...formData, successStories: e.target.value.split('\n') })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <div>
  <label htmlFor="venmoLink" className="block text-gray-700 font-bold mb-2">
    Venmo Link:
  </label>
  <input
    type="text"
    id="venmoLink"
    name="venmoLink"
    value={formData.venmoLink || ''}
    onChange={handleInputChange}
    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  />
</div>
<div>
  <label htmlFor="paypalLink" className="block text-gray-700 font-bold mb-2">
    PayPal Link:
  </label>
  <input
    type="text"
    id="paypalLink"
    name="paypalLink"
    value={formData.paypalLink || ''}
    onChange={handleInputChange}
    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  />
</div>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Save Profile
            </button>
          </form>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Availability</h2>
          <AvailabilityCalendar availability={availability} setAvailability={setAvailability} />
          <div className="mt-8">
            <h3 className="text-lg font-bold mb-2">Current Availability</h3>
            {Object.entries(availability).map(([date, { startTime, endTime }]) => (
              <div key={date} className="mb-2">
                <span className="font-bold">{date}:</span> {startTime} - {endTime}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Booked Sessions</h2>
        {bookedSessions.length > 0 ? (
          <ul className="space-y-4">
            {bookedSessions.map((session) => (
              <li key={session.id} className="bg-gray-100 p-4 rounded-md">
                <p className="text-gray-800">
                  <span className="font-bold">Date:</span> {format(new Date(session.date), 'yyyy-MM-dd')}
                </p>
                <p className="text-gray-800">
                  <span className="font-bold">Start Time:</span> {session.startTime}
                </p>
                <p className="text-gray-800">
                  <span className="font-bold">End Time:</span> {session.endTime}
                </p>
                <p className="text-gray-800">
                  <span className="font-bold">Student:</span> {session.studentName}
                </p>
                <button
                  onClick={() => handleCancelBooking(session.id)}
                  className="mt-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  Cancel Booking
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No booked sessions found.</p>
        )}
      </div>
    </div>
  );
};

export default TutorDashboard;
