import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const BookingConfirmation = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const bookingIdsParam = searchParams.get('bookingIds');
    const bookingIds = bookingIdsParam ? JSON.parse(decodeURIComponent(bookingIdsParam)) : [];

    const fetchBookings = async () => {
      try {
        const bookingPromises = bookingIds.map(async (bookingId) => {
          const bookingRef = doc(firestore, 'bookings', bookingId);
          const bookingDoc = await getDoc(bookingRef);
          if (bookingDoc.exists()) {
            return { id: bookingDoc.id, ...bookingDoc.data() };
          }
          return null;
        });

        const fetchedBookings = await Promise.all(bookingPromises);
        setBookings(fetchedBookings.filter((booking) => booking !== null));
      } catch (error) {
        console.error('Error fetching bookings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [location]);

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(':');
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? 'PM' : 'AM';
    const hour12 = hourInt % 12 || 12;
    return `${hour12}:${minute} ${ampm}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (bookings.length === 0) {
    return <div>No bookings found.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="px-6 py-4">
          <h2 className="text-3xl font-bold mb-2">Booking Confirmation</h2>
          <p className="text-gray-700 text-base mb-4">
            Thank you for your booking! Your bookings have been successfully confirmed.
          </p>
          {bookings.map((booking) => (
            <div key={booking.id} className="border-t border-gray-200 pt-4">
              <p className="text-gray-700 font-bold mb-2">Booking Details:</p>
              <p className="text-gray-700 mb-2">
                <span className="font-bold">Order Number:</span> {booking.id}
              </p>
              <p className="text-gray-700 mb-2">
                <span className="font-bold">Date:</span> {booking.date}
              </p>
              <p className="text-gray-700 mb-2">
                <span className="font-bold">Time:</span> {convertTo12HourFormat(booking.startTime)} - {convertTo12HourFormat(booking.endTime)}
              </p>
            </div>
          ))}
          <p className="text-gray-700 mt-4">
            You will receive a confirmation email shortly with the details of your bookings.
          </p>
          <p className="text-gray-700 mt-2">
            If you have any questions or need to make changes to your booking, please contact us.
          </p>
        </div>
        <div className="px-6 py-4 bg-gray-100 text-right">
          <Link
            to="/"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
