import React from 'react';
import { GiCrystalBars, GiRock } from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';
import {
  PricingSection,
  PricingWrapper,
  PricingHeading,
  PricingContainer,
  PricingCard,
  PricingCardInfo,
  PricingCardIcon,
  PricingCardPlan,
  PricingCardFeatures,
  PricingCardFeature,
  BookNowButton,
  PaymentMethodsContainer,
  PaymentMethodIcon,
  PaymentMethodLogo,
} from './Pricing.elements';

import PayPalLogo from '../../images/paypal-logo.png';
import VenmoLogo from '../../images/venmo-logo.png';
import CashAppLogo from '../../images/cashapp-logo.png';


const Pricing = () => {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <PricingSection>
        <PricingWrapper>
          <PricingHeading>Our Services</PricingHeading>
          <PricingContainer>
            <PricingCard>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiRock />
                </PricingCardIcon>
                <PricingCardPlan>Single Sessions</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>50 Minutes: $15</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
              <BookNowButton to="/tutor-list">Book Now</BookNowButton>
            </PricingCard>
            <PricingCard>
              <PricingCardInfo>
                <PricingCardIcon>
                  <GiCrystalBars />
                </PricingCardIcon>
                <PricingCardPlan>Package Deals</PricingCardPlan>
                <PricingCardFeatures>
                  <PricingCardFeature>4 lessons: $50</PricingCardFeature>
                  <PricingCardFeature>8 lessons: $100</PricingCardFeature>
                </PricingCardFeatures>
              </PricingCardInfo>
              <BookNowButton to="/tutor-list">Book Now</BookNowButton>
            </PricingCard>
          </PricingContainer>
          <PricingHeading>Payment Methods</PricingHeading>
          <PaymentMethodsContainer>
            <PaymentMethodIcon>
              <PaymentMethodLogo src={PayPalLogo} alt="PayPal" />
            </PaymentMethodIcon>
            <PaymentMethodIcon>
              <PaymentMethodLogo src={VenmoLogo} alt="Venmo" />
            </PaymentMethodIcon>
            <PaymentMethodIcon>
              <PaymentMethodLogo src={CashAppLogo} alt="CashApp" />
            </PaymentMethodIcon>
          </PaymentMethodsContainer>
          
        </PricingWrapper>
      </PricingSection>
    </IconContext.Provider>
  );
};

export default Pricing;