import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAXGpEu_L5zM7swc33iTqABlrn3FP_28Us",
    authDomain: "tutoring-50300.firebaseapp.com",
    projectId: "tutoring-50300",
    storageBucket: "tutoring-50300.appspot.com",
    messagingSenderId: "243562192023",
    appId: "1:243562192023:web:2e812238293448d660ddd2",
    measurementId: "G-898GZ9F1VE"
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const storage = getStorage(app);