import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import GlobalStyles from './globalStyles';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import TutorDashboard from './pages/TutorDashboard';
import TutorList from './pages/TutorList';
import HomePage from './pages/HomePage/Home';
import Services from './pages/Services/Services';
import SignUp from './pages/SignUp/SignUp';
import BookingPage from './pages/BookingPage';
import TutorSignIn from './pages/TutorSignIn';
import BookingConfirmation from './pages/BookingConfirmation';
import TutorDetails from './pages/TutorDetails';
import ContactPage from './pages/ContactPage';
import { CartProvider } from './contexts/CartContext';

function App() {
  return (
    <CartProvider>
      <Router>
        <GlobalStyles />
        <ScrollToTop />
        <div className="flex flex-col min-h-screen">
          <header className="bg-white shadow">
            <Navbar />
            
          </header>

          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/services" element={<Services />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/tutor-signin" element={<TutorSignIn />} />
              <Route path="/tutor-dashboard" element={<TutorDashboard />} />
              <Route path="/tutor-list" element={<TutorList />} />
              <Route path="/tutors/:tutorId" element={<TutorDetails />} />
              <Route path="/booking" element={<BookingPage />} />
              <Route path="/booking-confirmation" element={<BookingConfirmation />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>
          <Footer />
         
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
